<template>
  <div style="margin:100px auto;display:block;">

    <motorBusqueda></motorBusqueda>

    <v-layout justify-center row style="margin-top:50px;">
      <v-flex xs12 md8>
        <div style="width:90%;margin:0 auto;display:block;">
          <p v-if="Productos == ''">
            ASESOR(A), SIN PRODUCTOS
          </p>
          <v-layout row justify-center v-if="Productos != ''">
            <v-flex xs12 md6 v-for="(item, i) in Productos" :key="i" class="my-5">
              <v-card flat style="border-radius:0;border:solid 1px #ccc;width:95%;background:white;margin:0 auto;display:block;">
                <v-carousel hide-delimiters height="250">
                  <v-carousel-item v-if="item.img1 != ''" :src="item.img1"
                  >
                  <span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{item.pr_tipo}}
                  </span>
                  
                  <div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{item.pr_estado}}, {{item.pr_municipio}}
                    </p>
                  </div>


                  </v-carousel-item>
                  <v-carousel-item v-if="item.img2 != ''" :src="item.img2"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{item.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{item.pr_estado}}, {{item.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="item.img3 != ''" :src="item.img3"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{item.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{item.pr_estado}}, {{item.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="item.img4 != ''" :src="item.img4"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{item.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{item.pr_estado}}, {{item.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="item.img5 != ''" :src="item.img5"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{item.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{item.pr_estado}}, {{item.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="item.img6 != ''" :src="item.img6"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{item.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{item.pr_estado}}, {{item.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="item.img7 != ''" :src="item.img7"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{item.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{item.pr_estado}}, {{item.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="item.img8 != ''" :src="item.img8"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{item.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{item.pr_estado}}, {{item.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="item.img9 != ''" :src="item.img9"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{item.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{item.pr_estado}}, {{item.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                  <v-carousel-item v-if="item.img10 != ''" :src="item.img10"
                  ><span class="ml-5"
                  style="border-radius:10px;background:red;color:white;font-weight:700;padding:10px 10px;">
                    {{item.pr_tipo}}
                  </span><div style="position:absolute;bottom:0">
                    <p style="
                    background:#00174A;color:white;font-weight:600;padding:5px 15px;">
                      <i class="fas fa-map-marked-alt pr-1"></i> {{item.pr_estado}}, {{item.pr_municipio}}
                    </p>
                  </div></v-carousel-item>
                </v-carousel>
                <v-card-text>
                  <div 
                  @click="irInmueble({asesor: item.pr_asesor_uid, id:item.pr_uid})"
                  style="color:#00174A;cursor:pointer;" class="gohso">
                    {{item.pr_nombre}}
                  </div> <br><br>
                  <span style="color:#00174A;font-weight:600;font-size:17px;">
                    Ref: ${{formatNumber(item.pr_precio)}}
                  </span>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
      <v-flex xs12 md4>
        <div style="width:90%;margin:0 auto;display:block;">
          <v-card :elevation="6"
              flat style="border:solid 1px #ccc;border-radius:10px;" >
                  <v-img :src="DatosDelAsesor.img_profile" style="margin:0 auto;display:block;"></v-img>
                  <v-card-text>
                      <p class="text-center title">
                          {{DatosDelAsesor.username}}
                      </p>
                      <div class="text-center pb-2" style="font-weight:600;color:#D41700;font-style:italic;">
                          Asesor Inmobiliario
                      </div>
                      <p class="subtitle text-center"><strong>
                          <i class="fas fa-info-circle pl-2"></i> {{DatosDelAsesor.oficina}}
                          </strong></p>
                          <p>
                            <a :href="`mailto:${DatosDelAsesor.email}`" style="text-decoration:none;color:#333;font-size:10px;">
                                <i class="fas fa-mail-bulk"></i> {{DatosDelAsesor.email}}</a> <br><br>



                            <a :href="`https://api.whatsapp.com/send?phone=${DatosDelAsesor.tlf}&text=%20%20`" 
                            style="text-decoration:none;color:#333;">
                                <i class="fab fa-whatsapp pt-2"></i> {{DatosDelAsesor.tlf}} <br><br></a>


                            <a target="_blank" :href="`https://tiktok.com/@${DatosDelAsesor.tiktok}`"
                             style="text-decoration:none;color:#333"
                            >
                                <v-img style="float:left" class="mt-1"
                                src="https://image.flaticon.com/icons/png/512/1946/1946552.png" 
                                height="12" width="12"></v-img> <span class="px-2">TikTok</span>
                            </a><br><br>

                            
                            <a target="_blank" :href="`https://instagram.com/${DatosDelAsesor.instagram}`"
                             style="text-decoration:none;color:#333"
                            >
                                <i class="fab fa-instagram pr-2"></i> Instagram
                            </a>
                          </p>
                  </v-card-text>
              </v-card>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<style>
.gohso {
text-decoration:none;line-height:25px;font-weight:700;font-size:20px;color:#00174A;
}
.gohso:hover{
  text-decoration: underline;
}
</style>

<script>
import axios from 'axios'
import motorBusqueda from '../motorBusqueda.vue'
export default {
    data(){
        return {
          uid:'',Productos:[],DatosDelAsesor:[]
        }
    },
    methods:{
      formatNumber(n) {
            n = String(n).replace(/\D/g, "");
        return n === '' ? n : Number(n).toLocaleString('es');
        },
      irInmueble(e){
            const _ID = e.id;
            const _ASESOR = e.asesor;
            const _URL = "/Inmueble?inmu="+_ID+"&asesor="+_ASESOR;
            window.open(_URL, "_self")
        },
      async loadProductosPorAsesor(e){
        try {
          const URL_INMOGLOBALVE_ASESORES_INMUEBLES = 'https://inmoglobalve.com.ve/php/Productos/getProductosPorAsesor.php'
          await axios.post(URL_INMOGLOBALVE_ASESORES_INMUEBLES,{pr_asesor_uid:e}).then(res=>{
            console.log(this.Productos = res.data)
          })
        } catch (error) {
          console.log(error)
        }
      },
      loadAsesorResponsable(e){
            const URL_INMOGLOBAL_VE = 'https://inmoglobalve.com.ve/php/LoadAsesoresSingle.php';
            axios.post(URL_INMOGLOBAL_VE,{uid:e}).then(res=>{
                this.DatosDelAsesor = res.data[0]
            })
        },
    },
    components:{
      motorBusqueda
    },
    mounted(){
        const valores = window.location.search;
        const urlParams = new URLSearchParams(valores);
        this.uid = urlParams.get('uid')
        this.loadProductosPorAsesor(this.uid),this.loadAsesorResponsable(this.uid)
      }
    }
</script>